<script>
import {useNow} from '@vueuse/core'
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import request from "axios";


dayjs.extend(duration)

export default {
  name: "WeekView",
  computed: {
    dayjs() {
      return dayjs
    },
    day() {
      return dayjs(useNow.value).day()
    },
    endTime() {
      return dayjs(useNow.value).hour(18).minute(0).second(0);
    },
    diff() {
      return dayjs(useNow.value).hour(18).minute(0).second(0).diff(dayjs(useNow.value))
    },
    conf() {
      return this.data[this.day] || {}
    },
    logoStr() {
      return this.conf.logo
    }
  },
  data() {
    return {
      useNow: useNow(),
      // useNow: {
      //   value: '2023-11-30 19:00:00'
      // },
      data: {},
      visible: false
    }
  },
  created() {
    request("/week.json").then(res => {
      this.data = res.data
    })
  },
  methods: {
    show() {
      this.visible = true
      console.log("mouseover")
    },
    getDiff(nowValue) {
      let now = dayjs(nowValue);
      let endTime = dayjs(nowValue).hour(18).minute(0).second(0);
      let res = dayjs.duration(endTime - now);
      return `${res.hours()}h ${res.minutes()}m ${res.seconds()}s`
    }
  }
}
</script>

<template>
  <div style="padding: 0 5px; cursor: pointer;">
    <el-popover
        placement="top-start"
        trigger="hover">
      <div v-if="visible">
        <div class="flex">
          <div class="flex flex-col p-1"
               v-if="conf.h1 || conf.h2"
               style="font-family: '阿里妈妈方圆体 VF Regular'; font-weight: 700; font-size: 20px; writing-mode: vertical-lr; align-items: center">
            <p>{{ conf.h1 }}</p>
            <p>{{ conf.h2 }}</p>
          </div>
          <div class="flex flex-col flex-grow p-1 items-center" style="width: 100%;">
            <div class="week-image-detail">
              <el-image v-if="conf.logoDetail || conf.logo" :src="conf.logoDetail || conf.logo"></el-image>
              <div v-html="conf.logoDetailSvg || conf.logoSvg" v-else>
              </div>
            </div>
            <div style="min-width: 80px; text-align: center">
              <div v-if="day === 0 || day === 6 || endTime.diff(dayjs(useNow.value)) <= 0" class="flex items-center">
                <div v-if="day === 0 || day === 6">
                  周末愉快
                </div>
                <div v-else>
                  恭喜下班
                </div>
                <span><svg t="1701325831604" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="13305" width="30" height="30"><path
                    d="M754.346667 805.546667s33.28 57.173333 44.373333 97.28c11.093333 40.106667 39.253333 38.4 84.48 30.72 45.226667-7.68 23.04 34.133333-7.68 58.88-30.72 24.746667-75.946667-41.813333-96.426667-71.68-19.626667-29.013333-82.773333-55.466667-82.773333-55.466667l58.026667-59.733333z"
                    fill="#231815" p-id="13306"></path><path
                    d="M861.013333 1001.813333h-4.266666c-29.866667-4.266667-64-54.613333-81.066667-78.506666-18.773333-28.16-80.213333-53.76-81.066667-53.76l-5.973333-2.56 66.56-68.266667 2.56 5.12c1.706667 2.56 33.28 58.026667 45.226667 98.133333 10.24 34.986667 31.573333 35.84 79.36 27.306667 15.36-2.56 25.6 0 29.013333 8.533333 6.826667 14.506667-11.946667 40.96-33.28 58.026667-5.12 4.266667-11.093333 5.973333-17.066667 5.973333z m-157.013333-137.386666c16.213333 6.826667 62.293333 29.013333 79.36 54.613333 14.506667 21.333333 48.64 70.826667 75.093333 75.093333 5.973333 0.853333 10.24-0.853333 15.36-4.266666 20.48-16.213333 34.986667-39.253333 30.72-47.786667-1.706667-4.266667-8.533333-5.12-19.626666-3.413333-46.08 7.68-76.8 10.24-88.746667-33.28-9.386667-32.426667-33.28-76.8-40.96-91.306667l-51.2 50.346667z"
                    fill="#231815" p-id="13307"></path><path
                    d="M820.053333 916.48c2.56 5.12 0.853333 11.946667-4.266666 14.506667l-23.893334 12.8c-5.12 2.56-11.946667 0.853333-14.506666-4.266667-2.56-5.12-0.853333-11.946667 4.266666-14.506667l23.893334-12.8c5.12-2.56 11.946667-0.853333 14.506666 4.266667z"
                    fill="#545971" p-id="13308"></path><path
                    d="M929.28 506.026667c0 231.253333-194.56 418.133333-435.2 418.133333s-435.2-186.88-435.2-418.133333 194.56-418.133333 435.2-418.133334c239.786667 0 435.2 187.733333 435.2 418.133334"
                    fill="#FFE04A" p-id="13309"></path><path
                    d="M493.226667 936.96c-246.613333 0-448-192.853333-448-430.933333S246.613333 75.093333 493.226667 75.093333s448 192.853333 448 430.933334-200.533333 430.933333-448 430.933333z m0-836.266667c-232.96 0-423.253333 181.76-423.253334 405.333334s189.44 405.333333 423.253334 405.333333 423.253333-181.76 423.253333-405.333333-189.44-405.333333-423.253333-405.333334z"
                    fill="#545971" p-id="13310"></path><path
                    d="M490.666667 792.746667c-177.493333 0-331.093333-57.173333-404.48-180.053334C136.533333 787.626667 298.666667 915.626667 490.666667 915.626667s354.133333-128 404.48-302.933334c-73.386667 122.88-226.986667 180.053333-404.48 180.053334"
                    fill="#FFD05A" p-id="13311"></path><path
                    d="M138.24 110.08c-1.706667 0-4.266667-0.853333-5.973333-1.706667-5.973333-3.413333-8.533333-11.093333-5.12-17.066666 23.04-44.373333 79.36-62.293333 125.44-40.106667 5.973333 3.413333 8.533333 10.24 5.973333 17.066667-3.413333 5.973333-10.24 8.533333-17.066667 5.973333-34.133333-16.213333-75.093333-3.413333-92.16 29.013333-2.56 4.266667-6.826667 6.826667-11.093333 6.826667zM558.933333 73.386667c-4.266667 0-7.68-1.706667-10.24-5.12-20.48-29.866667-64-37.546667-95.573333-17.92-5.973333 3.413333-13.653333 1.706667-17.066667-3.413334s-1.706667-13.653333 3.413334-17.066666c42.666667-27.306667 100.693333-17.066667 129.706666 23.893333 4.266667 5.973333 2.56 13.653333-3.413333 17.066667-1.706667 1.706667-4.266667 2.56-6.826667 2.56zM759.466667 456.533333c-52.906667 15.36-108.373333 87.04-261.12 124.586667-151.04 42.666667-225.28 4.266667-279.893334 17.066667-53.76 12.8-50.346667 57.173333-20.48 105.813333 0 0 98.986667 157.866667 360.106667 88.746667 261.12-68.266667 270.506667-254.293333 270.506667-254.293334 2.56-57.173333-16.213333-97.28-69.12-81.92"
                    fill="#545971" p-id="13312"></path><path
                    d="M553.813333 776.533333c-240.64 63.146667-334.506667-80.213333-335.36-81.92-18.773333-29.866667-24.746667-55.466667-16.213333-69.12 1.706667-3.413333 5.973333-7.68 17.92-10.24h1.706667c16.213333-4.266667 36.693333-0.853333 60.586666-0.853333 43.52 0 107.52 12.8 196.266667-10.24 7.68-1.706667 15.36-4.266667 23.04-5.973333 7.68-1.706667 15.36-4.266667 23.04-5.973334C613.546667 569.173333 663.04 529.066667 708.266667 503.466667c21.333333-11.946667 39.253333-25.6 55.466666-29.866667h1.706667c11.946667-3.413333 17.066667-1.706667 20.48 0.853333 13.653333 7.68 21.333333 32.426667 19.626667 68.266667 0 4.266667-11.946667 170.666667-251.733334 233.813333z"
                    fill="#FFFFFF" p-id="13313"></path><path
                    d="M254.376928 587.017101l7.42831-1.950028 77.134436 293.830933-7.42831 1.950028z" fill="#545971"
                    p-id="13314"></path><path
                    d="M371.534099 556.236705l7.432142-1.935374 76.554786 293.982489L448.088886 850.219194z"
                    fill="#545971" p-id="13315"></path><path
                    d="M488.113359 525.615907l7.428105-1.950806 77.165206 293.822855-7.428106 1.950806z" fill="#545971"
                    p-id="13316"></path><path
                    d="M604.997022 494.905488l7.428106-1.950805 77.165205 293.822854-7.428106 1.950806z" fill="#545971"
                    p-id="13317"></path><path
                    d="M722.296836 464.101257l7.433458-1.930314 76.354661 294.034529-7.433457 1.930315z" fill="#545971"
                    p-id="13318"></path><path
                    d="M188.586667 352.426667c-7.68 0-15.36-5.973333-16.213334-14.506667-6.826667-44.373333 24.746667-87.04 70.826667-93.866667 22.186667-3.413333 44.373333 1.706667 62.293333 13.653334 18.773333 12.8 30.72 31.573333 34.133334 53.76 1.706667 9.386667-5.12 17.92-13.653334 18.773333-9.386667 1.706667-17.92-5.12-18.773333-13.653333-1.706667-12.8-9.386667-23.893333-19.626667-31.573334-11.093333-7.68-24.746667-11.093333-38.4-8.533333-28.16 4.266667-46.933333 29.866667-42.666666 55.466667 1.706667 9.386667-5.12 17.92-13.653334 18.773333-2.56 1.706667-3.413333 1.706667-4.266666 1.706667zM468.48 314.026667c-8.533333 0-16.213333-6.826667-17.066667-15.36-2.56-45.226667 32.426667-84.48 79.36-87.04 46.08-3.413333 87.04 30.72 89.6 75.946666 0.853333 9.386667-5.973333 17.066667-15.36 17.92-9.386667 0.853333-17.066667-5.973333-17.92-15.36-1.706667-26.453333-25.6-46.933333-53.76-45.226666-28.16 1.706667-49.493333 24.746667-47.786666 51.2 0.853333 9.386667-5.973333 17.066667-15.36 17.92h-1.706667z"
                    fill="#545971" p-id="13319"></path><path
                    d="M349.013333 887.466667L384 609.28s-192.853333-70.826667-275.626667 27.306667C26.453333 735.573333 49.493333 770.56 69.12 837.973333c18.773333 67.413333 42.666667 118.613333 128 125.44 85.333333 6.826667 151.893333-75.946667 151.893333-75.946666"
                    fill="#FFE04A" p-id="13320"></path><path
                    d="M207.36 976.213333c-3.413333 0-7.68 0-11.093333-0.853333-95.573333-7.68-120.32-68.266667-139.093334-134.826667-1.706667-6.826667-4.266667-12.8-5.973333-19.626666-17.92-58.026667-30.72-99.84 48.64-193.706667 87.893333-104.106667 281.6-34.133333 289.28-30.72 5.973333 1.706667 9.386667 7.68 8.533333 13.653333L362.666667 887.466667c0 2.56-0.853333 4.266667-2.56 6.826666-3.413333 4.266667-67.413333 81.92-152.746667 81.92zM244.906667 597.333333c-46.933333 0-95.573333 11.093333-126.293334 47.786667-69.973333 82.773333-59.733333 115.2-43.52 169.813333 1.706667 5.973333 4.266667 12.8 5.973334 19.626667 17.92 61.44 37.546667 109.226667 116.906666 116.053333 69.12 5.973333 127.146667-55.466667 139.093334-69.12l33.28-262.826666C349.866667 611.84 297.813333 597.333333 244.906667 597.333333z"
                    fill="#545971" p-id="13321"></path><path
                    d="M315.733333 959.146667c68.266667 23.04 114.346667-29.866667 150.186667-81.066667 134.826667-162.986667-147.626667-394.24-233.813333-285.866667 0 0-83.626667-14.506667-81.066667 61.44 0 0-57.173333 11.093333-41.813333 69.973334 15.36 58.88 128 27.306667 130.56 87.04 3.413333 59.733333-17.92 109.226667 75.946666 148.48"
                    fill="#FFE04A" p-id="13322"></path><path
                    d="M348.16 977.066667c-11.093333 0-23.04-1.706667-35.84-5.973334h-0.853333c-85.333333-35.84-84.48-83.626667-82.773334-129.706666 0-9.386667 0.853333-19.626667 0-29.866667-0.853333-18.773333-17.92-24.746667-52.053333-32.426667-32.426667-7.68-68.266667-15.36-78.506667-52.053333-5.973333-21.333333-3.413333-40.106667 5.973334-55.466667 9.386667-15.36 25.6-23.893333 34.986666-27.306666 0.853333-20.48 8.533333-35.84 21.333334-47.786667 21.333333-18.773333 52.906667-18.773333 67.413333-17.066667 20.48-22.186667 51.2-30.72 87.04-25.6 75.093333 11.946667 157.866667 83.626667 187.733333 162.986667 23.04 60.586667 13.653333 121.173333-25.6 168.96-24.746667 33.28-66.56 91.306667-128.853333 91.306667z m-28.16-29.866667c54.613333 17.92 93.866667-17.92 135.68-76.8 0 0 0-0.853333 0.853333-0.853333 43.52-52.053333 35.84-107.52 22.186667-144.213334-26.453333-70.826667-102.4-136.533333-168.106667-146.773333-20.48-3.413333-48.64-1.706667-67.413333 21.333333-2.56 3.413333-7.68 5.12-11.946667 4.266667 0 0-34.133333-5.973333-53.76 11.093333-9.386667 7.68-13.653333 20.48-12.8 37.546667 0 5.973333-4.266667 11.946667-10.24 12.8 0 0-19.626667 4.266667-29.013333 18.773333-5.973333 9.386667-6.826667 20.48-2.56 34.986667 5.12 19.626667 26.453333 26.453333 58.88 34.133333 31.573333 6.826667 69.973333 16.213333 72.533333 56.32 0.853333 11.093333 0 22.186667 0 32.426667-1.706667 43.52-2.56 75.946667 65.706667 104.96z"
                    fill="#545971" p-id="13323"></path><path
                    d="M238.08 592.213333c44.373333 9.386667 79.36 17.066667 122.88 74.24" fill="#FFE04A"
                    p-id="13324"></path><path
                    d="M360.96 679.253333c-4.266667 0-7.68-1.706667-10.24-5.12-40.96-53.76-71.68-59.733333-115.2-69.12l5.12-24.746666-2.56 12.8 2.56-12.8c45.226667 9.386667 83.626667 17.92 130.56 78.506666 4.266667 5.973333 3.413333 13.653333-2.56 17.92-1.706667 1.706667-4.266667 2.56-7.68 2.56z"
                    fill="#545971" p-id="13325"></path><path
                    d="M151.04 651.946667c-26.453333 1.706667 75.093333-11.946667 129.706667 51.2" fill="#FFE04A"
                    p-id="13326"></path><path
                    d="M280.746667 715.946667c-3.413333 0-6.826667-1.706667-9.386667-4.266667-37.546667-43.52-99.84-46.933333-117.76-46.933333h-0.853333-0.853334c-5.12 0-14.506667 0.853333-17.92-9.386667-1.706667-5.973333 0.853333-11.946667 5.973334-14.506667 0.853333-0.853333 3.413333-0.853333 6.826666-0.853333h6.826667c28.16 0 95.573333 7.68 136.533333 55.466667 4.266667 5.12 4.266667 13.653333-0.853333 17.92-2.56 0.853333-5.12 2.56-8.533333 2.56z"
                    fill="#545971" p-id="13327"></path><path
                    d="M256.853333 424.106667c5.973333 38.4-21.333333 74.24-60.586666 80.213333-39.253333 5.973333-76.8-19.626667-82.773334-57.173333-5.973333-38.4 21.333333-74.24 60.586667-80.213334 40.106667-6.826667 76.8 18.773333 82.773333 57.173334"
                    fill="#FCBD83" p-id="13328"></path><path
                    d="M745.813333 332.8c5.973333 38.4-21.333333 74.24-60.586666 80.213333-39.253333 5.973333-76.8-19.626667-82.773334-57.173333-5.973333-38.4 21.333333-74.24 60.586667-80.213333 40.106667-6.826667 76.8 18.773333 82.773333 57.173333"
                    fill="#FCBD83" p-id="13329"></path><path
                    d="M861.013333 70.826667c-6.826667 8.533333-18.773333 10.24-28.16 3.413333-8.533333-6.826667-10.24-18.773333-3.413333-28.16 0 0 25.6-17.92 34.133333-11.093333 9.386667 6.826667-2.56 35.84-2.56 35.84z"
                    fill="#53586F" p-id="13330"></path><path
                    d="M845.653333 82.773333c-5.12 0-10.24-1.706667-14.506666-5.12-10.24-8.533333-11.946667-23.04-4.266667-34.133333h0.853333c6.826667-5.12 29.013333-19.626667 39.253334-11.093333 10.24 7.68 1.706667 33.28-1.706667 40.96l-0.853333 0.853333c-4.266667 5.12-9.386667 8.533333-16.213334 9.386667-0.853333-0.853333-1.706667-0.853333-2.56-0.853334z m-12.8-33.28c-5.12 6.826667-3.413333 16.213333 3.413334 21.333334 3.413333 2.56 7.68 3.413333 11.093333 3.413333 4.266667-0.853333 7.68-2.56 10.24-5.12 4.266667-11.946667 7.68-27.306667 4.266667-29.866667-4.266667-3.413333-17.92 3.413333-29.013334 10.24z"
                    fill="#545971" p-id="13331"></path><path
                    d="M837.12 57.173333c-134.826667-79.36-281.6 19.626667-257.706667 30.72 93.013333 10.24 285.013333 117.76 329.386667 253.44 9.386667-13.653333 63.146667-204.8-71.68-284.16z"
                    fill="#DD6B73" p-id="13332"></path><path
                    d="M907.093333 349.866667l-2.56-7.68C860.16 207.36 666.453333 102.4 579.413333 92.16h-1.706666c-3.413333-1.706667-5.12-4.266667-5.12-6.826667-0.853333-11.093333 19.626667-23.893333 28.16-29.013333 44.373333-26.453333 144.213333-57.173333 238.933333-1.706667 46.933333 27.306667 75.946667 70.826667 87.04 128 14.506667 75.946667-7.68 152.746667-14.506667 162.133334l-5.12 5.12zM581.12 84.48c89.6 11.093333 276.48 110.933333 327.68 245.76 7.68-23.04 21.333333-85.333333 9.386667-146.773333-11.093333-54.613333-38.4-95.573333-82.773334-122.026667C740.693333 5.12 640 39.253333 600.746667 65.706667c-14.506667 9.386667-18.773333 16.213333-19.626667 18.773333z"
                    fill="#545971" p-id="13333"></path><path
                    d="M652.8 112.64c-0.853333 0-1.706667 0-2.56-0.853333-1.706667-1.706667-1.706667-4.266667-0.853333-5.973334 33.28-39.253333 130.56-68.266667 182.613333-53.76 2.56 0.853333 3.413333 2.56 3.413333 5.12-0.853333 2.56-2.56 3.413333-5.12 2.56-48.64-12.8-142.506667 14.506667-174.08 51.2-0.853333 1.706667-1.706667 1.706667-3.413333 1.706667z"
                    fill="#545971" p-id="13334"></path><path
                    d="M854.186667 245.76c-0.853333 0-1.706667 0-1.706667-0.853333-1.706667-0.853333-2.56-3.413333-1.706667-5.973334 23.04-42.666667 14.506667-139.946667-14.506666-180.906666-1.706667-1.706667-0.853333-4.266667 0.853333-5.973334 1.706667-1.706667 4.266667-0.853333 5.973333 0.853334 31.573333 43.52 40.106667 144.213333 15.36 190.293333-1.706667 1.706667-3.413333 2.56-4.266666 2.56z"
                    fill="#545971" p-id="13335"></path></svg></span>
              </div>
              <div v-else>
                <div>距离下班</div>
                <div>{{ getDiff(useNow.value) }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div slot="reference"
           @mouseover="show" class="week-image-icon">
        <el-image v-if="conf.logo" :src="conf.logo" style="width: 30px; height: 30px"></el-image>
        <div v-html="conf.logoSvg" v-if="conf.logoSvg">
        </div>
      </div>
    </el-popover>
  </div>

</template>

<style lang="scss">

.week-image-icon {
  svg {
    width: 30px;
    height: 30px;
  }
}

.week-image-detail {
  svg {
    width: 80px;
    height: 80px;
  }

  .el-image {
    width: 80px;
    height: 80px;
  }

}


</style>
