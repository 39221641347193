import { render, staticRenderFns } from "./BitoChatItem.vue?vue&type=template&id=20f9ebca&scoped=true&"
import script from "./BitoChatItem.vue?vue&type=script&lang=js&"
export * from "./BitoChatItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f9ebca",
  null
  
)

export default component.exports