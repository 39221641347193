
// for Prod
let bitoAIUrl = 'https://bitoai.bito.co/';
let bitoUTUrl = 'https://ut.bito.co/';
let managementAPI = 'https://mgmtapi.bito.co/';
// for staging
// let bitoAIUrl = 'https://app.bito.co/';
// let bitoUTUrl = 'https://app.bito.co/';
// let managementAPI ='https://staging.bito.co/';

let IconsPath = './assets/images/';

let APIOBJ = {
    explainCode: bitoAIUrl + 'ai/v1/explaincode/',
    chat: bitoAIUrl + 'ai/v2/chat/',
    aiToken: bitoUTUrl + 'tra/v1/token/',
    tokenAPI: managementAPI + 'api/token',
    createWs: managementAPI + 'api/verifyOTPAndCreateWS/',
    userTracker: bitoUTUrl + 'tra/v1/userTracker/',
    questions: bitoAIUrl + 'ai/v1/questions/',
    feedback: bitoUTUrl + 'tra/v1/eventTracker/',
    VscodeIcon: IconsPath + 'VSCode.svg',
    JetBrainsIcon: IconsPath + 'JetBrains.svg',
    ChromeIcon: IconsPath + 'Chrome.svg',
    CLIIcon: IconsPath + 'CLI.svg',
    CopyIcon: IconsPath + 'content_copy.svg',
    currentVersionWeb: '3.0'
};

export default APIOBJ
