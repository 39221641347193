<template>
  <div style="width: 100vw">
    <div style="max-width: 700px; margin: 10px auto 0;">
      <div class="bg-white h-38 shadow relative"
           style="border: 1px solid rgb(224, 224, 224); border-radius: 10px 10px 20px 20px;">
        <div style="transition: opacity 300ms ease-in-out 0s; opacity: 1;">
          <div class="absolute top-6 right-8" style="color: rgb(204, 204, 204);"><h6 class="text-left text-sm">
            <span>❄</span>️️</h6><h4 class="text-4xl text-center"><span
              class="font-digit ac-temperature">16</span><small class="font-digit">°C</small></h4></div>
        </div>
        <div class="text-center mt-28"></div>
        <div class="mt-1" style="border: 1px solid rgb(224, 224, 224);"></div>
        <div class="absolute h-1 w-1 rounded-full top-32 right-2" style="background-color: rgb(56, 247, 9);"></div>
        <div class="absolute p-1"
             style="background-color: rgb(78, 165, 245); border-radius: 2px; top: 10px; left: 10px; height: 70px; width: 50px;">
          <div class="flex justify-center" style="--ac-c-text-dot: white;"><span class="text-dot rounded-full"
                                                                                 style="width: 4px; height: 4px; margin: 0px 2px;"></span><span
              class="text-dot rounded-full" style="width: 4px; height: 4px; margin: 0px 2px;"></span><span
              class="text-dot rounded-full" style="width: 4px; height: 4px; margin: 0px 2px;"></span><span
              class="text-dot rounded-full" style="width: 4px; height: 4px; margin: 0px 2px;"></span><span
              class="text-dot rounded-full" style="width: 4px; height: 4px; margin: 0px 2px;"></span><span
              class="text-dot rounded-full" style="width: 4px; height: 4px; margin: 0px 2px;"></span></div>
          <div class="my-1 h-7 w-full bg-white" style="padding: 2px 8px;">
            <div class="flex">
              <div style="background-color: green; width: 40%;"></div>
              <div
                  style="margin-left: 40%; height: 3px; border-width: 1.5px 2px 1.5px 0px; border-style: solid; border-color: transparent green transparent transparent;"></div>
              <div style="background-color: green; width: 10%;"></div>
            </div>
            <div class="energy-label-level" style="background-color: lightgreen; width: 50%;"></div>
            <div class="energy-label-level" style="background-color: rgb(255, 193, 7); width: 60%;"></div>
            <div class="energy-label-level" style="background-color: orange; width: 70%;"></div>
            <div class="energy-label-level" style="background-color: red; width: 80%;"></div>
          </div>
          <div class="w-full h-5 bg-white" style="margin-bottom: 2px; padding-top: 0.8px;">
            <div class="flex justify-center" style="--ac-c-text-dot: black;"><span class="text-dot rounded-full"
                                                                                   style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 2px 0.8px;"></span></div>
            <div class="w-full h-0" style="border-top: 1px solid; margin: 0.8px 0px;"></div>
            <div class="flex justify-center" style="--ac-c-text-dot: black;"><span class="text-dot rounded-full"
                                                                                   style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.5px; height: 1.5px; margin: 2px 0.8px;"></span></div>
            <div class="flex justify-center" style="--ac-c-text-dot: black;"><span class="text-dot rounded-full"
                                                                                   style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span><span
                class="text-dot rounded-full" style="width: 1.2px; height: 1.2px; margin: 0px 0.8px;"></span></div>
          </div>
          <div class="flex justify-center" style="--ac-c-text-dot: white;"><span class="text-dot rounded-full"
                                                                                 style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span><span
              class="text-dot rounded-full" style="width: 2px; height: 2px; margin: 0px 0.8px;"></span></div>
        </div>
        <a class="adsense-link" target="_blank" rel="noreferrer">
          <div class="energy-saving-label">
            <div class="energy-saving-label_bg"><span
                class="energy-saving-label_title">节能产品&nbsp;&nbsp;惠民工程</span>
              <div class="i-mdi:home-lightbulb-outline adsense-logo flex rounded-lg w-16 my-4 op-80"></div>
              <span class="energy-saving-label_description">推广上限价格：XXXX 元</span><span
                  class="energy-saving-label_description">政府补助金额：XXXX 元</span><span
                  class="energy-saving-label_description">补助上限价格：XXXX 元</span></div>
          </div>
        </a></div>
      <div style="transition: opacity 300ms ease-in-out 0s; opacity: 1;">
        <div class="wind-effect flex justify-center my-2">
          <div class="wind-line h-5 w-1"
               style="background-color: rgb(187, 187, 187); transform: rotate(10deg);"></div>
          <div class="wind-line h-5 w-1 mx-20" style="background-color: rgb(187, 187, 187);"></div>
          <div class="wind-line h-5 w-1"
               style="background-color: rgb(187, 187, 187); transform: rotate(-10deg);"></div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
export default {
  name: "AirConditioner",
  data() {
    return {
      isShow: false,
    }
  }
}
</script>

<style scoped lang="scss">
*, :before, :after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: var(--un-default-border-color, #e5e7eb)
}

:before, :after {
  --un-content: ""
}

html, :host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent
}

body {
  margin: 0;
  line-height: inherit
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px
}

abbr:where([title]) {
  text-decoration: underline dotted
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit
}

a {
  color: inherit;
  text-decoration: inherit
}

b, strong {
  font-weight: bolder
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0
}

button, select {
  text-transform: none
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none
}

:-moz-focusring {
  outline: auto
}

:-moz-ui-invalid {
  box-shadow: none
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0
}

fieldset {
  margin: 0;
  padding: 0
}

legend {
  padding: 0
}

ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0
}

dialog {
  padding: 0
}

textarea {
  resize: vertical
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af
}

button, [role=button] {
  cursor: pointer
}

:disabled {
  cursor: default
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle
}

img, video {
  max-width: 100%;
  height: auto
}

[hidden] {
  display: none
}

:root {
  --ac-bg-color: transparent;
  --ac-text-color: #141414
}

html.dark {
  --ac-bg-color: #121212;
  --ac-text-color: #fafafa
}

@font-face {
  font-family: Digital-7 Mono;
  src: url(/assets/fonts/digital-7-mono.ttf) format("truetype")
}

.font-digit {
  font-family: Digital-7 Mono
}

.ac-text {
  color: var(--ac-text-color)
}

body {
  margin: 0;
  min-height: 90vh;
  background-color: var(--ac-bg-color);
  color: var(--ac-text-color);
  transition: background-color .2s;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

*, :before, :after {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: rgb(147 197 253 / .5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia:
}

::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: rgb(147 197 253 / .5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia:
}

.i-ic-round-ac-unit {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M21 11h-3.17l2.54-2.54a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0L15 11h-2V9l3.95-3.95c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0L13 6.17V3c0-.55-.45-1-1-1s-1 .45-1 1v3.17L8.46 3.63a.996.996 0 0 0-1.41 0c-.39.39-.39 1.03 0 1.42L11 9v2H9L5.05 7.05c-.39-.39-1.03-.39-1.42 0a.996.996 0 0 0 0 1.41L6.17 11H3c-.55 0-1 .45-1 1s.45 1 1 1h3.17l-2.54 2.54a.996.996 0 0 0 0 1.41c.39.39 1.03.39 1.42 0L9 13h2v2l-3.95 3.95c-.39.39-.39 1.03 0 1.42c.39.39 1.02.39 1.41 0L11 17.83V21c0 .55.45 1 1 1s1-.45 1-1v-3.17l2.54 2.54c.39.39 1.02.39 1.41 0c.39-.39.39-1.03 0-1.42L13 15v-2h2l3.95 3.95c.39.39 1.03.39 1.42 0a.996.996 0 0 0 0-1.41L17.83 13H21c.55 0 1-.45 1-1s-.45-1-1-1'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-ic-round-wb-sunny {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='m6.05 4.14l-.39-.39a.993.993 0 0 0-1.4 0l-.01.01a.984.984 0 0 0 0 1.4l.39.39c.39.39 1.01.39 1.4 0l.01-.01a.984.984 0 0 0 0-1.4M3.01 10.5H1.99c-.55 0-.99.44-.99.99v.01c0 .55.44.99.99.99H3c.56.01 1-.43 1-.98v-.01c0-.56-.44-1-.99-1m9-9.95H12c-.56 0-1 .44-1 .99v.96c0 .55.44.99.99.99H12c.56.01 1-.43 1-.98v-.97c0-.55-.44-.99-.99-.99m7.74 3.21c-.39-.39-1.02-.39-1.41-.01l-.39.39a.984.984 0 0 0 0 1.4l.01.01c.39.39 1.02.39 1.4 0l.39-.39a.984.984 0 0 0 0-1.4m-1.81 15.1l.39.39a.996.996 0 1 0 1.41-1.41l-.39-.39a.993.993 0 0 0-1.4 0c-.4.4-.4 1.02-.01 1.41M20 11.49v.01c0 .55.44.99.99.99H22c.55 0 .99-.44.99-.99v-.01c0-.55-.44-.99-.99-.99h-1.01c-.55 0-.99.44-.99.99M12 5.5c-3.31 0-6 2.69-6 6s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6m-.01 16.95H12c.55 0 .99-.44.99-.99v-.96c0-.55-.44-.99-.99-.99h-.01c-.55 0-.99.44-.99.99v.96c0 .55.44.99.99.99m-7.74-3.21c.39.39 1.02.39 1.41 0l.39-.39a.993.993 0 0 0 0-1.4l-.01-.01a.996.996 0 0 0-1.41 0l-.39.39c-.38.4-.38 1.02.01 1.41'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-ic\:outline-emoji-objects {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 3c-.46 0-.93.04-1.4.14c-2.76.53-4.96 2.76-5.48 5.52c-.48 2.61.48 5.01 2.22 6.56c.43.38.66.91.66 1.47V19c0 1.1.9 2 2 2h.28a1.98 1.98 0 0 0 3.44 0H14c1.1 0 2-.9 2-2v-2.31c0-.55.22-1.09.64-1.46A6.956 6.956 0 0 0 19 10c0-3.87-3.13-7-7-7m2 14h-4v-1h4zm-4 2v-1h4v1zm5.31-5.26c-.09.08-.16.18-.24.26H8.92c-.08-.09-.15-.19-.24-.27c-1.32-1.18-1.91-2.94-1.59-4.7c.36-1.94 1.96-3.55 3.89-3.93c.34-.07.68-.1 1.02-.1c2.76 0 5 2.24 5 5c0 1.43-.61 2.79-1.69 3.74'/%3E%3Cpath fill='currentColor' d='M11.5 11h1v3h-1z'/%3E%3Cpath fill='currentColor' d='m9.672 9.58l.707-.706l2.122 2.121l-.707.707z'/%3E%3Cpath fill='currentColor' d='m12.208 11.712l-.707-.707l2.121-2.121l.707.707z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-ic\:round-power-settings-new {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 3c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1m5.14 2.86a.99.99 0 0 0-.01 1.39c1.13 1.2 1.83 2.8 1.87 4.57c.09 3.83-3.08 7.13-6.91 7.17A6.981 6.981 0 0 1 5 12c0-1.84.71-3.51 1.87-4.76c.37-.39.37-1-.01-1.38a.993.993 0 0 0-1.43.02A8.92 8.92 0 0 0 3 11.74c-.14 4.88 3.83 9.1 8.71 9.25c5.1.16 9.29-3.93 9.29-9c0-2.37-.92-4.51-2.42-6.11c-.38-.41-1.04-.42-1.44-.02'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-cloud {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M6.5 20q-2.28 0-3.89-1.57Q1 16.85 1 14.58q0-1.95 1.17-3.48q1.18-1.53 3.08-1.95q.63-2.3 2.5-3.72Q9.63 4 12 4q2.93 0 4.96 2.04Q19 8.07 19 11q1.73.2 2.86 1.5q1.14 1.28 1.14 3q0 1.88-1.31 3.19T18.5 20Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-earth {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8M11 19.93c-3.95-.49-7-3.85-7-7.93c0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2m1-16A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-github {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-loading {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 4V2A10 10 0 0 0 2 12h2a8 8 0 0 1 8-8'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-sina-weibo {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M9.82 13.87c1.07 0 1.95.87 1.95 1.95a1.95 1.95 0 0 1-1.95 1.95c-1.08 0-1.95-.88-1.95-1.95c0-1.08.87-1.95 1.95-1.95M14.5 3.34l.68-.03c3.76 0 6.82 3.06 6.82 6.82l-.05.82l-1.19-.37l.02-.45c0-3.09-2.51-5.6-5.6-5.6l-.35.01zm.82 2.89c2.06.07 3.73 1.77 3.76 3.83l-1.24-.38c-.19-1.12-1.06-2-2.17-2.18zM2 15.41c-.03-.61.07-2.77 2.95-5.44c3.4-3.16 4.87-2.92 4.87-2.92s3.18-.3 1.24 3.41h.07c.47-.5 1.49-1.25 3.56-1.46c2.08-.21 2.08 1.5 1.81 2.7c1.88.94 3.06 2.33 3.06 3.88c0 2.82-3.93 5.11-8.78 5.11h-.28c-3.5 0-6.5-1.27-7.79-3.1c-.46-.62-.71-1.3-.71-2.01zm7.82-3.49c-3.23 0-5.85 1.75-5.85 3.9s2.62 3.9 5.85 3.9c3.23 0 5.85-1.75 5.85-3.9s-2.62-3.9-5.85-3.9'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-telegram {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M9.78 18.65l.28-4.23l7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3L3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z' fill='currentColor'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-triangle-small-down {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M8 9h8l-4 7'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-triangle-small-up {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M8 15h8l-4-7'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-twitter {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.i-mdi-wechat {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M9.5 4C5.36 4 2 6.69 2 10c0 1.89 1.08 3.56 2.78 4.66L4 17l2.5-1.5c.89.31 1.87.5 2.91.5A5.22 5.22 0 0 1 9 14c0-3.31 3.13-6 7-6c.19 0 .38 0 .56.03C15.54 5.69 12.78 4 9.5 4m-3 2.5a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m5 0a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1M16 9c-3.31 0-6 2.24-6 5s2.69 5 6 5c.67 0 1.31-.08 1.91-.25L20 20l-.62-1.87C20.95 17.22 22 15.71 22 14c0-2.76-2.69-5-6-5m-2 2.5a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.right-2 {
  right: .5rem
}

.right-8 {
  right: 2rem
}

.top-32 {
  top: 8rem
}

.top-6 {
  top: 1.5rem
}

.m-2 {
  margin: .5rem
}

.m-6 {
  margin: 1.5rem
}

.m-auto {
  margin: auto
}

.mx, [mx=""] {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem
}

.my, .my-4, [my-4=""] {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

[mx~="0.8"] {
  margin-left: .2rem;
  margin-right: .2rem
}

[mx~="2"] {
  margin-left: .5rem;
  margin-right: .5rem
}

[my~="0"] {
  margin-top: 0;
  margin-bottom: 0
}

[my~="2"] {
  margin-top: .5rem;
  margin-bottom: .5rem
}

.ms {
  margin-inline-start: 1rem
}

.mt-1 {
  margin-top: .25rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-20 {
  margin-top: 5rem
}

.mt-28 {
  margin-top: 7rem
}

.mt-8 {
  margin-top: 2rem
}

.block {
  display: block
}

[size~="1.2"] {
  width: .3rem;
  height: .3rem
}

[size~="1.5"] {
  width: .375rem;
  height: .375rem
}

[size~="2"] {
  width: .5rem;
  height: .5rem
}

[size~="4"] {
  width: 1rem;
  height: 1rem
}

.h-0 {
  height: 0
}

.h-1 {
  height: .25rem
}

.h-10 {
  height: 2.5rem
}

.h-38 {
  height: 9.5rem
}

.h-5 {
  height: 1.25rem
}

.h-7 {
  height: 1.75rem
}

.max-w-600px {
  max-width: 600px
}

.w-1 {
  width: .25rem
}

.w-16, [w-16=""] {
  width: 4rem
}

.w-full {
  width: 100%
}

.flex, [flex=""] {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.flex-col {
  flex-direction: column
}

.transform {
  transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}

.cursor-pointer {
  cursor: pointer
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.border {
  border-width: 1px
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg, [rounded-lg=""] {
  border-radius: .5rem
}

.bg-white {
  --un-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--un-bg-opacity))
}

.p-1 {
  padding: .25rem
}

.p-2 {
  padding: .5rem
}

.px, [px=""] {
  padding-left: 1rem;
  padding-right: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.text-center {
  text-align: center
}

.text-left {
  text-align: left
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-8xl {
  font-size: 6rem;
  line-height: 1
}

.text-lg, [text-lg=""] {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

[color~=black] {
  --un-text-opacity: 1;
  color: rgb(0 0 0 / var(--un-text-opacity))
}

[color~=white] {
  --un-text-opacity: 1;
  color: rgb(255 255 255 / var(--un-text-opacity))
}

.op-80, .opacity-80, [op-80=""] {
  opacity: .8
}

.shadow {
  --un-shadow: var(--un-shadow-inset) 0 1px 3px 0 var(--un-shadow-color, rgb(0 0 0 / .1)), var(--un-shadow-inset) 0 1px 2px -1px var(--un-shadow-color, rgb(0 0 0 / .1));
  box-shadow: var(--un-ring-offset-shadow), var(--un-ring-shadow), var(--un-shadow)
}

.transition {
  transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.hot-color {
  filter: saturate(110%)
}

.cold-color {
  filter: saturate(90%)
}

.rc-button {
  margin: 8px !important
}

.ac-temperature {
  text-shadow: 0 0 2px rgba(0, 0, 0, .1)
}

.wind-effect {
  opacity: .3
}

:root {
  --ac-c-text-dot: black
}

.text-dot {
  background-color: var(--ac-c-text-dot)
}

.energy-label-level {
  margin-top: 2px;
  height: 3px
}

.energy-saving-label {
  color: #000;
  opacity: .8;
  position: absolute;
  top: 10px;
  left: 63px;
  background-color: #4caf50;
  padding: 12px;
  border-radius: 2px;
  transform: scale(.22);
  transform-origin: left top
}

.energy-saving-label_bg {
  padding: 10px;
  width: 200px;
  border-radius: 15px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.energy-saving-label_title {
  font-size: 20px;
  display: block;
  margin: 2px auto
}

.energy-saving-label_description {
  font-size: 12px
}

.adsense-text-link {
  color: #63a5ef;
  text-decoration: none;
  cursor: pointer
}

.adsense-logo {
  margin-bottom: 10px;
  font-size: 3.5rem
}

.adsense-logo.animated {
  animation: iconAnimate 1.5s ease-in-out infinite
}

@keyframes iconAnimate {
  0%, to {
    transform: scale(1)
  }

  10%, 30% {
    transform: scale(.9)
  }

  20%, 40%, 60%, 80% {
    transform: scale(1.1)
  }

  50%, 70% {
    transform: scale(1.1)
  }
}

</style>
