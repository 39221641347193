<template>
  <div style="margin-top: 50px">
    <el-row :gutter="10" v-for="(item, index) in navData" :key="index" class="text-black">
      <el-divider content-position="left">
        <b>{{ item.title }}：</b>
      </el-divider>
      <el-col :lg="4" :md="6" :sm="8" :xs="12"
              v-for="(detail, index) in item.data"
              :key="index"
              style="margin-bottom: 10px">
        <div draggable="true"
             @dragstart="dragstart($event)">
          <el-card shadow="hover" :body-style="{padding: '10px', height: '80px'}"
                   style="height: 100px; width: 100%; user-select: none; cursor: pointer; border-radius: 6px;">
            <div style="display: flex; flex-flow: column; justify-content: space-between; height: 80px"
                 @click="click(detail)">
              <div style="display: flex">
                <el-image
                    :src="detail.logo"
                    :style="{backgroundColor: detail.logoColor}"
                    style="width: 30px; height: 30px; margin-right: 5px; border-radius: 8px;"/>
                <span
                    class="title-color"
                    style="display: inline-block; line-height: 30px; font-size: 14px">{{
                    detail.name
                  }}</span>
              </div>
              <span class="desc-color" style="font-size: 15px">{{ detail.desc }}</span>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import request from "axios";

export default {
  name: "info-nav",
  data() {
    return {
      navData: []
    }
  },
  mounted() {
    request.get("/data.json").then(res => {
      this.navData = res.data
    })
  },
  methods: {
    click(item) {
      let link = document.createElement("a")
      link.href = item.href
      link.setAttribute("target", "_blank")
      link.click();
    },
    dragstart(event) {
      console.log(event)
    }
  }
}
</script>

<style lang="scss" scoped>
.desc-color {
  color: rgba(107, 114, 128, 1);
  //color: #E5EAF3;
}

.title-color {
  color: rgba(37, 99, 235, 1);
  font-weight: 700;
}

.dark {
  .title-color {
      //color: rgba(96, 165, 250, 1);;
    color: #E4E4E4;
    //font-weight: unset;

    //color: #33b3ae;
  }
  .desc-color {
    //color: rgba(107, 114, 128, 1);
    //color: #E5EAF3;
    color: rgba(235,245,255,.5);
  }
  .el-card:hover {
    //box-shadow: var(--color-mktg-btn-shadow-hover) !important;
    box-shadow: rgba(240,240,240,.5) 0 0 0 1.3px inset;

    .title-color {
      //font-weight: 700;
    }

  }
}



</style>
