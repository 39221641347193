<template>
  <div>
    <div style="display: flex">
      <el-button class="BitChatIcon"
                 @click="bitChatIconClick"
                 icon=""
                 size="medium">
        <i class="el-icon-chat-dot-square" style="font-size: 16px; font-weight: bolder; padding-right: 2px"></i>
         BitoChat
      </el-button>
    </div>
    <div>
      <BitoChat v-if="bitoChatView">

      </BitoChat>
    </div>
  </div>

</template>

<script>
import BitoChat from "@/components/bito/BitoChat.vue";

export default {
  name: "BitoChatIcon",
  components: {BitoChat},
  data() {
    return {
      bitoChatView: false
    }
  },
  methods: {
    bitChatIconClick(event) {
      this.bitoChatView = !this.bitoChatView

      let target = event.target;
      if (target.nodeName === "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
    }
  }
}
</script>

<style scoped lang="scss">

.BitChatIcon {
  border: none;
  font-weight: bold;
  padding: 10px;
  background-color: unset;
  color: white;
  border-radius: 12px;
}

.BitChatIcon:hover {
  background-color: white;
  color: black;
}

.BitChatIcon:active, :focus {
  background-color: unset !important;
  color: white !important;
}


</style>
