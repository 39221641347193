<template>
  <div ref="docSearch" class="myDocSearch"></div>
</template>

<script>
import docsearch from "@docsearch/js";
import '@docsearch/css';

export default {
  name: "DocSearch",
  mounted() {
    const translations = {
      button: {
        buttonText: '搜索',
        buttonAriaLabel: '搜索',
      },
      modal: {
        searchBox: {
          resetButtonTitle: '重置',
          resetButtonAriaLabel: '重置',
          cancelButtonText: '取消',
          cancelButtonAriaLabel: '取消',
        },
        startScreen: {
          recentSearchesTitle: '最近',
          noRecentSearchesText: '无搜索记录',
          saveRecentSearchButtonTitle: 'Save this search',
          removeRecentSearchButtonTitle: '从搜索记录中移除',
          favoriteSearchesTitle: '喜爱',
          removeFavoriteSearchButtonTitle: '从喜爱中移除',
        },
        errorScreen: {
          titleText: 'Unable to fetch results',
          helpText: 'You might want to check your network connection.',
        },
        footer: {
          selectText: '搜索',
          selectKeyAriaLabel: 'Enter key',
          navigateText: '选择',
          navigateUpKeyAriaLabel: 'Arrow up',
          navigateDownKeyAriaLabel: 'Arrow down',
          closeText: '关闭',
          closeKeyAriaLabel: 'Escape key',
          searchByText: '',
        },
        noResultsScreen: {
          noResultsText: '无结果',
          suggestedQueryText: '尝试用以下查询重试',
          reportMissingResultsText: 'Believe this query should return results?',
          reportMissingResultsLinkText: 'Let us know.',
        },
      },
    };

    docsearch({
      container: this.$refs.docSearch,
      // appId: 'R2IYF7ETH7',
      // apiKey: '599cec31baffa4868cae4e79f180729b',
      // indexName: 'docsearch',
      appId: "59TK6SFZHU",
      apiKey: "ff88a03e049040dfd8b94c2f02ec2008",
      indexName: "nav.kingtang",
      translations: translations,
      placeholder: "搜索",
      initialQuery: '',
      maxResultsPerGroup: 20,
      searchParameters: {
        attributesToRetrieve: [
          "hierarchy.lvl0",
          "hierarchy.lvl1",
          "hierarchy.lvl2",
          "hierarchy.lvl3",
          "hierarchy.lvl4",
          "hierarchy.lvl5",
          "hierarchy.lvl6",
          "content",
          "type",
          "url",
          "logo"
        ]
      },
      hitComponent({ hit, children }) {
        if(children.props && children.props.children && children.props.children[0].props) {
          let icon = children.props.children[0].props.children[1];

          if(icon && icon.props) {
            icon.props.children = []
            icon.props.style = `background-image: url(${hit.logo}); background-size: 20px;`
          }

        }

        return {
          type: "a",
          ref: undefined,
          constructor: undefined,
          key: undefined,
          props: {
            href: hit.url,
            target: "_blank",
            // onClick: (event) => {
            //   console.log(children)
            //   console.log(event);
            // },
            children
          },
          __v: null
        };
      }
    });
  }
}
</script>


<style>
.DocSearch-Button {
  transition: .1s;
  background-color: unset;
  margin: 0 0 0 8px;
  height: 34px;
  color: white;
  border-radius: 12px;
}

.DocSearch-Button-Placeholder {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.DocSearch-Button-Keys {
  padding-top: 2px;
}

.DocSearch-Button-Key {
  color: #44484A;
  font-size: 14px;
}

.DocSearch-Button:hover {
  box-shadow: unset;
  .DocSearch-Search-Icon {
    color: black !important;
  }
}

.DocSearch-Button-Container {
  .DocSearch-Search-Icon {
    width: 15px;
    height: 15px;
    font-weight: bold;
    color: white !important;
  }
}



.DocSearch-Button:hover {
  //background-color: #ebedf0;
}


</style>
