
const isUndefined = (val) => val === void 0;
const isBoolean = (val) => typeof val === "boolean";
const isNumber = (val) => typeof val === "number";
const isString = (val) => typeof val === "string";
const isEmpty = (val) => !val && val !== 0 || isArray(val) && val.length === 0 || isObject(val) && !Object.keys(val).length;
const isElement = (e) => {
    if (typeof Element === "undefined")
        return false;
    return e instanceof Element;
};
const isStringNumber = (val) => {
    if (!isString(val)) {
        return false;
    }
    return !Number.isNaN(Number(val));
};
function addUnit(value, defaultUnit = "px") {
    if (!value && value !== 0)
        return "";
    if (isNumber(value) || isStringNumber(value)) {
        return `${value}${defaultUnit}`;
    } else if (isString(value)) {
        return value;
    }
}

export default {
    // v-dialog-drag: 弹窗拖拽
    bind: (targetRef, dragHeader) => {
        let dragRef = dragHeader;

        let transform = {
            offsetX: parseInt((localStorage.getItem('dragDomLeft') || '').replace('px', '')) || 60,
            offsetY: parseInt((localStorage.getItem('dragDomTop') || '').replace('px', '')) || 60,
        };

        const onMousedown = (e) => {
            const downX = e.clientX;
            const downY = e.clientY;
            const { offsetX, offsetY } = transform;
            const targetRect = targetRef.getBoundingClientRect();

            const targetLeft = targetRect.left;
            const targetTop = targetRect.top;
            const targetWidth = targetRect.width;
            const targetHeight = targetRect.height;
            const clientWidth = document.documentElement.clientWidth;
            const clientHeight = document.documentElement.clientHeight;
            const minLeft = -targetLeft + offsetX;
            const minTop = -targetTop + offsetY;
            const maxLeft = clientWidth - targetLeft - targetWidth + offsetX;
            const maxTop = clientHeight - targetTop - targetHeight + offsetY;

            // e.preventDefault()
            const onMousemove = (e2) => {
                const moveX = Math.min(Math.max(offsetX + e2.clientX - downX, minLeft), maxLeft);
                const moveY = Math.min(Math.max(offsetY + e2.clientY - downY, minTop), maxTop);
                transform = {
                    offsetX: moveX,
                    offsetY: moveY
                };
                if (targetRef) {
                    targetRef.style.transform = `translate(${addUnit(moveX)}, ${addUnit(moveY)})`;
                    let rect = targetRef.getBoundingClientRect();
                    localStorage.setItem("dragDomLeft", `${rect.left}px`)
                    localStorage.setItem("dragDomTop", `${rect.top}px`)
                }
            };
            const onMouseup = () => {
                document.removeEventListener("mousemove", onMousemove);
                document.removeEventListener("mouseup", onMouseup);
            };
            document.addEventListener("mousemove", onMousemove);
            document.addEventListener("mouseup", onMouseup);
            return {
                onMousemove,
                onMouseup
            };
        };
        dragRef.addEventListener("mousedown", onMousedown);
        return {
            onMousedownFun: onMousedown,
        }
    }


}
