<template>
  <div style="font-size: 13px !important;">
    <div id="questionContainer">
      <div class="Ques-Ans-Text-div"><span style="float: right; font-size: 13px">我: </span></div>
      <div id="question" class="question-block"><span class="question-text-span">
      <pre
          style="font-family: var(--stdFontFamily);margin: 0px;white-space: break-spaces; font-size: 13px">{{question}}</pre></span>
      </div>
    </div>
    <div class="answer-div">
      <div class="Ques-Ans-Text-div">回复:</div>
    </div>
    <div class="answer-received-cls">
      <span class="cursor" v-if="loading"></span>
      <div ref="view"></div>
    </div>
  </div>
</template>

<script>
import environment from "@/components/bito/enviornment";
import {linkify} from "@/components/bito/view";

export default {
  name: "BitoChatItem",
  props: {
    question: {
      type: String
    },
    answer: {
      type: String
    }
  },
  data() {
    return {
      dataReceivedTillNow: '',
      dataReceivedHtml: '',
      loading: true,
    }
  },
  watch: {
    answer() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
    this.$bus.$on("handleStop", () => {
      this.loading = false
    })
  },
  methods: {
    refresh() {
      if (this.answer) {
        let text = this.answer;
        let elem_ = document.getElementById("test");
        let processed_ = false;
        let countVal = 0;
        let codeblockOrig = '';
        let codeblockReplaced = '';
        let codeblockStart = 0;

        if (!elem_) return;

        //inline function used in ide-plugins to remove language/newline from the beginning
        let languageNameRemover = (match_) => {
          let tar = match_.valueOf();
          let slipttedAryy = tar.split("\n");
          if (tar.length <= 1) {
            return match_;
          }

          var shufter = (elm) => {
            if (elm.length <= 1) {
              return elm;
            }
            const matchCount = elm[0].match(/\w+/g);
            if (matchCount == null || (matchCount != null && matchCount.length == 1)) {
              elm.shift();
              shufter(elm)
            }

            return elm;
          }

          return shufter(slipttedAryy).join("\n");
        }

        text = text.replace(/</gm, '&lt;');
        // This code will take a text string and look for any code blocks. It will then remove the language name from the code block and wrap it in a div with the class name 'code-block'. Finally, it will add the closing  </pre></div>  tag to the text string.
        while (!processed_) {
          if (text.includes('```')) {
            if (countVal % 2 === 0) {
              codeblockStart = text.indexOf('```') + 3;
            }
            codeblockOrig = text.substring(codeblockStart, codeblockStart + 15);
            codeblockReplaced = languageNameRemover(codeblockOrig);
            text = text.replace(codeblockOrig, codeblockReplaced)
            let replaceString = countVal % 2 === 0 ? '<div class="code-block" style="margin-block: 8px;"><pre style="margin:0px"><code class="hljs">' : '</code></pre></div>';
            text = text.replace(/```([\s\S]*?)[\n\s]?([\s\S]*)/gm, (match, p1, p2) => {
              let trimmedtext = p1.replace(/^\s+/gm, '') + p2;
              return '```' + trimmedtext;
            });
            text = text.replace('```', replaceString);
            ++countVal;
            text = text.trim();
          } else {
            processed_ = true;
          }
        }

        //logic for handling code blocks with single back ticks `code`
        let single_processed_ = false;
        let single_countVal = 0;
        //This while loop replaces the character "`" with either an opening or closing tag for inline code blocks
        while (!single_processed_) {
          if (text.includes("`")) {
            let replaceTring = (single_countVal % 2 === 0) ? ' ' + '<div class="inline-code-block"><pre style="margin:0px; display: inline;white-space: pre-line;"><code>' + ' ' : ' ' + '</code></pre></div>' + ' ';
            text = text.replace("`", replaceTring);
            ++single_countVal;
          } else {
            single_processed_ = true;
          }
        }
        this.$refs.view.innerHTML = linkify(text, 'blue')
        let codeBlocks = elem_.querySelectorAll('code.hljs');
        codeBlocks.forEach((block) => {
          hljs.highlightBlock(block);
        });
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
