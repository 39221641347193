export function linkify(text, linkColor) {
  const urlRegex = /(?:^|\s)(https?:\/\/(?:www\.)?\S+)|(?:^|\s)(www\.\S+)|(?:^|\s)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})(?=$|\s)/gi;
  const urlInBracketsRegex = /\((?:(?:https?:\/\/|www\.)\S+)\)/gi;
  const urlInBracesRegex = /(?:\{(?:(?:https?:\/\/|www\.)\S+)\})/gi;
  const urlInSquareBracketsRegex = /(?:\[(?:(?:https?:\/\/|www\.)\S+)\])/gi;

  text = text.replace(urlRegex, function (url) {
    url = url.trim();
    if (url.startsWith('http') || url.startsWith('www')) {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return ' <a class="' + (linkColor === 'blue' ? 'TextBox-Link-Blue' : 'TextBox-Link-White') + '" href="' + url + '" target="_blank">' + url + '</a> ';
    } else if (url.includes('@')) {
      return ' <a class="' + (linkColor === 'blue' ? 'TextBox-Link-Blue' : 'TextBox-Link-White') + '" href="mailto:' + url + '">' + url + '</a> ';
    } else {
      return ' ' + url + ' ';
    }
  });

  text = text.replace(urlInBracketsRegex, function (url) {
    url = url.trim().slice(1, -1);
    if (url.startsWith('http') || url.startsWith('www')) {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return ' (<a class="' + (linkColor === 'blue' ? 'TextBox-Link-Blue' : 'TextBox-Link-White') + '" href="' + url + '" target="_blank">' + url + '</a>)';
    } else {
      return ' ' + url + ' ';
    }
  });

  text = text.replace(urlInBracesRegex, function (url) {
    url = url.trim().slice(1, -1);
    if (url.startsWith('http') || url.startsWith('www')) {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return ' {' + '<a class="' + (linkColor === 'blue' ? 'TextBox-Link-Blue' : 'TextBox-Link-White') + '" href="' + url + '" target="_blank">' + url + '</a>' + '}';
    } else {
      return ' ' + url + ' ';
    }
  });

  text = text.replace(urlInSquareBracketsRegex, function (url) {
    url = url.trim().slice(1, -1);
    if (url.startsWith('http') || url.startsWith('www')) {
      if (!url.startsWith('http')) {
        url = 'http://' + url;
      }
      return ' [' + '<a class="' + (linkColor === 'blue' ? 'TextBox-Link-Blue' : 'TextBox-Link-White') + '" href="' + url + '" target="_blank">' + url + '</a>' + ']';
    } else {
      return ' ' + url + ' ';
    }
  });

  return text.trim();
}
